// React & Gatsby
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom
import PillButton from "../PillButton";
import SchemaProduct from "../Schema/SchemaProduct";


const FeaturedProduct = ((props) => {
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark (
                    filter: {
                        fileAbsolutePath: { regex: "\/stories\/" }
                    }
                )
                {
                    edges {
                        node {
                            id
                            frontmatter {
                                slug
                                title
                                subtitle
                                alt
                                date
                                image
                                thumbnail
                                price
                                category
                                features
                                link
                                sku
                                text
                                brand
                            }
                            html
                            rawMarkdownBody
                            excerpt
                        }
                    }
                }
            }
        `
    )

    return (
        <React.Fragment>
            {allMarkdownRemark.edges.map((data, i) => {
                const product = data.node;

                if (product.frontmatter.slug !== props.slug) return '';

                let buttonText = 'Download Now';
                if (parseInt(product.frontmatter.price) !== 0 ) {
                    buttonText = `Buy Now - $${product.frontmatter.price}`;
                }

                return (
                    <Container
                        key={product.frontmatter.slug}
                        className={'mt-5 justify-content-center'}
                    >
                        <Row className={'featured justify-content-center mr-0 ml-0'}>
                            <Col md={6}>
                                <Image
                                    src={product.frontmatter.image}
                                    className={'col-md-12'}
                                />
                            </Col>
                            <Col md={6}>
                                <h1>{product.frontmatter.title}</h1>
                                <h2 className={'mb-5'}>{product.frontmatter.subtitle}</h2>
                                <p className={'mb-5'} dangerouslySetInnerHTML={{ __html : product.html }} />
                                <div className="buttons roundicons-button">
                                    <PillButton
                                        link={product.frontmatter.link}
                                        text={buttonText}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <SchemaProduct
                            name={product.frontmatter.title}
                            image={`https://vectoricons.net${product.frontmatter.image}`}
                            description={product.html}
                            brand={product.frontmatter.brand}
                            url={product.frontmatter.link}
                            price={product.frontmatter.price}
                            sku={product.frontmatter.sku}
                        />
                    </Container>
                )
            })}
        </React.Fragment>
    )
})

export default FeaturedProduct;
