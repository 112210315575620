/* eslint-disable */

// React & Gatsby
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom
import Page from "../components/Layout/Page"
import Products from '../components/Content/Products';
import MainMenu from "../components/MainMenu";
import PillButton from "../components/PillButton";
import FeaturedProduct from "../components/Content/FeaturedProduct";

// Data & Styles
import "../assets/scss/App.scss"
import menuItems from "../data/menu-items.json";

class IllustrationsPage extends Component {
    render() {
        return (
            <Page className={'roundicons'}>
                <Jumbotron
                    className={'home-page-hero justify-content-center'}
                    style={{
                        backgroundColor : '#98ad61',
                    }}
                >
                    <MainMenu menuItems={menuItems}/>
                    <FeaturedProduct slug={'featured-product'} />
                </Jumbotron>

                <Container className={'mt-4 mb-4'}>
                    <Products category={"illustration"} colWidth={4} />
                </Container>
            </Page>
        )
    }
}

export default IllustrationsPage
